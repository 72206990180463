body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');

.font-poppins {
  font-family: 'Poppins', sans-serif;
}
/* Add these styles to your global CSS file or in a <style> block */

.navbar {
  @apply fixed w-full z-20 transition-all duration-300;
}

.navbar-white {
  @apply bg-white shadow-lg py-2; /* Adjusted padding for white background */
}

.navbar-transparent {
  @apply bg-transparent py-4; /* Default padding for transparent background */
}

.logo {
  @apply transition-transform duration-300; /* Smooth scaling */
}

.logo-large {
  transform: scale(1.2); /* Scale logo to 120% of its original size */
}


