/* Modal container styling */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000; /* Make sure it's above other elements */
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Modal content styling */
.modal-content {
    position: relative;
    max-height: 90vh; /* Limit height to viewport */
    overflow-y: auto; /* Enable vertical scrolling */
    width: 100%;
    max-width: 500px; /* Limit modal width */
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

/* Prevent body scroll when modal is open */
body.modal-open {
    overflow: hidden;
}
